import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { usePageContext } from "../context/PageContext";
import { LinkButtonColor, StyledButton } from "../styled/StyledButton";
import * as styles from "./yourEvent.module.scss";
import { DevisQuery } from "../../../graphql-types";

export const YourEvent = () => {
  const { lang } = usePageContext();
  const { sanitySettings } = useStaticQuery<DevisQuery>(QUERY);

  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <h2>{sanitySettings.devis?._rawTitle?.[lang]}</h2>
        <p>{sanitySettings.devis?._rawSubtitle?.[lang]}</p>
        <div className={styles.buttonsWrapper}>
          <div>
            <p>{sanitySettings.devis?._rawCtaDevis?.[lang]}</p>
            <StyledButton
              url="/devis"
              color={LinkButtonColor.white}
              label={sanitySettings.devis?._rawLabelButtonDevis?.[lang]}
              // label={sanitySettings.devis?._rawCtaDevis?.[lang]}
              className={styles.button}
            />
          </div>
          <div>
            <p>{sanitySettings.devis?._rawCtaContact?.[lang]}</p>
            <StyledButton
              url="/contact"
              color={LinkButtonColor.white}
              label={sanitySettings.devis?._rawLabelButtonContact?.[lang]}
              className={styles.button}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QUERY = graphql`
  query Devis {
    sanitySettings {
      devis {
        _rawTitle
        _rawSubtitle
        _rawCtaContact
        _rawCtaDevis
        _rawLabelButtonContact
        _rawLabelButtonDevis
      }
    }
  }
`;
