import React, { useState } from "react";
import Swiper from "swiper";
import { Carousel } from "../carousel/Carousel";
import { graphql, Link, useStaticQuery } from "gatsby";
import {
  CarouselHomeQuery,
  IncontournablesCarouselQuery,
  SanitySelection,
} from "../../../graphql-types";
import { SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./IncontournablesCarousel.module.scss";
import { usePageContext } from "../context/PageContext";
import { StyledLink } from "../styled/StyledLink";
import { CarouselPagination } from "../carousel/CarouselPagination";
import { slugify } from "../../utils/slugify";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CarouselControls } from "../carousel/CarouselControls";

export const IncontournablesCarousel = () => {
  const { sanityHomePage } =
    useStaticQuery<IncontournablesCarouselQuery>(QUERY);
  const { lang } = usePageContext();
  const [swiper, setSwiper] = useState<Swiper>();
  const { width } = useWindowSize();

  return (
    <>
      <CarouselControls swiper={swiper} className={styles.controls}>
        <div className={styles.wrapper}>
          <div className={styles.carouselContainer}>
            <Carousel setSwiper={setSwiper}>
              {width < 1024 &&
                sanityHomePage?.data?.incontournables?.incontournablesItems?.map(
                  (item, index: number) => (
                    <SwiperSlide key={`carouselitem-${item?._key ?? index}`}>
                      <StyledLink
                        path={
                          `/event/${slugify(item?.content?._rawTitle?.fr)}` ??
                          ""
                        }
                      >
                        <div className={styles.slide}>
                          <GatsbyImage
                            image={
                              item?.content?.img?.image?.asset?.gatsbyImageData
                            }
                            alt={item?.content?.img?._rawAlt?.[lang] ?? "tours"}
                            className={styles.image}
                          />
                          <div className={styles.contentWrapper}>
                            <div>
                              <div className={styles.title}>
                                {item?.content?._rawTitle?.[lang]}
                              </div>
                              <div className={styles.cta}>
                                {item?.content?._rawCta?.[lang]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </StyledLink>
                    </SwiperSlide>
                  )
                )}
              {width >= 1024 &&
                sliceArray(
                  sanityHomePage?.data?.incontournables?.incontournablesItems ??
                    [],
                  3
                )?.map((item, index: number) => (
                  <SwiperSlide key={`carouselChunk-${index}`}>
                    <div className={styles.chunkWrapper}>
                      {item.map((item: SanitySelection, index: number) => (
                        <div key={`carouselitemDesktop-${item?._key ?? index}`}>
                          <StyledLink
                            path={
                              `/event/${slugify(
                                item?.content?._rawTitle?.fr
                              )}` ?? ""
                            }
                          >
                            <div className={styles.slide}>
                              <GatsbyImage
                                image={
                                  item?.content?.img?.image?.asset
                                    ?.gatsbyImageData
                                }
                                alt={
                                  item?.content?.img?._rawAlt?.[lang] ?? "tours"
                                }
                                className={styles.image}
                              />
                              <div className={styles.contentWrapper}>
                                <div>
                                  <div className={styles.title}>
                                    {item?.content?._rawTitle?.[lang]}
                                  </div>
                                  <div className={styles.cta}>
                                    {item?.content?._rawCta?.[lang]}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </StyledLink>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}
            </Carousel>
          </div>
          <CarouselPagination swiper={swiper} spacing={10} />
        </div>
      </CarouselControls>
    </>
  );
};

const QUERY = graphql`
  query IncontournablesCarousel {
    sanityHomePage {
      data {
        incontournables {
          incontournablesItems {
            ... on SanitySelection {
              _key
              content {
                _rawCta
                _rawTitle
                img {
                  _rawAlt
                  image {
                    asset {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            ... on SanitySubEvent {
              _key
              content {
                _rawCta
                _rawTitle
                img {
                  _rawAlt
                  image {
                    asset {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const sliceArray = (arr: any[], chunkSize: number) => {
  let chunkedArray: any[] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunkedArray.push(chunk);
  }
  return chunkedArray;
};
