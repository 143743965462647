import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { LinkedinPostsQuery } from "../../../graphql-types";
import { LinkedInEmbed } from "react-social-media-embed";

export const LnkPosts = () => {
  const { sanityHomePage } = useStaticQuery<LinkedinPostsQuery>(QUERY);

  const posts = sanityHomePage?.data?.linkedinPosts
    ?.map(post =>
      post?.url?.replace("linkedin.com/feed/", "linkedin.com/embed/feed/")
    )
    .filter(url => url);

  return (
    <>
      {posts?.map(post => (
        <LinkedInEmbed url={post as string} />
      ))}
    </>
  );
};

export const QUERY = graphql`
  query LinkedinPosts {
    sanityHomePage {
      data {
        linkedinPosts {
          url
        }
      }
    }
  }
`;
