import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { usePageContext } from "../context/PageContext";
import { HomeIncontournablesQuery } from "../../../graphql-types";
import * as styles from "./homeIncontournables.module.scss";
import { IncontournablesCarousel } from "./IncontournablesCarousel";
import BlockContent from "@sanity/block-content-to-react";

export const HomeIncontournables: React.FC = () => {
  const { lang } = usePageContext();
  const { sanityHomePage } = useStaticQuery<HomeIncontournablesQuery>(QUERY);
  const incontournables = sanityHomePage?.data?.incontournables;

  return (
    <div className={styles.wrapper}>
      <header>
        <h2>{incontournables?._rawIncontournablesTitle?.[lang]}</h2>
        <div className={styles.block}>
          <BlockContent
            blocks={incontournables?._rawIncontournablesDesc?.[lang]}
          />
        </div>
      </header>
      <IncontournablesCarousel />
    </div>
  );
};

const QUERY = graphql`
  query HomeIncontournables {
    sanityHomePage {
      data {
        incontournables {
          _rawIncontournablesTitle
          _rawIncontournablesDesc
        }
      }
    }
  }
`;
