const chars = /[?+#'’,."]/g;
const whiteSpace = /^\s+|\s+$/g;
const spacesReplace = /[-\s]+/g;

export const slugify = (name: string) =>
  name
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(chars, "")
    .replace(whiteSpace, "")
    .replace(spacesReplace, "-")
    .toLowerCase();
