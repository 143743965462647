import * as React from "react";
import { Link, graphql, useStaticQuery, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../styles/pages/index.module.scss";
import { usePageContext } from "../components/context/PageContext";
import { useEffect, useState } from "react";
import { Layout } from "../components/layout/Layout";
import { Footer } from "../components/layout/Footer";
import { HomeCarousel } from "../components/home/HomeCarousel";
import { HomeIncontournables } from "../components/home/HomeIncontournables";
import { HomeMetaQuery } from "../../graphql-types";
import { HomePush } from "../components/home/HomePush";
import { Testimonials } from "../components/home/Testimonials";
import { YourEvent } from "../components/widget/YourEvent";
import { SEO } from "../components/seo/seo";
import { LnkPosts } from "../components/linkedin/LnkPosts";

interface Props {
  data: HomeMetaQuery;
  location: any;
}

const IndexPage: React.FC<Props> = ({ data, location }) => {
  const { sanityHomePage } = data;

  return (
    <>
      <SEO rawMeta={sanityHomePage?._rawMetadata} />
      <div className={styles.wrapper}>
        <div className={styles.homeCarouselWrapper}>
          <HomeCarousel />
        </div>
        <div className={styles.incontournablesWrapper}>
          <HomeIncontournables />
        </div>
        {/* <div className={styles.pushWrapper}> */}
        <YourEvent />
        {/* </div> */}
        {/* <div className={styles.pushWrapper}>
          <HomePush />
        </div> */}
        <div className={styles.testimonialsWrapper}>
          <Testimonials />
        </div>
        <div className={styles.linkedinWrapper}>
          <div>
            <LnkPosts />
          </div>
        </div>
      </div>
    </>
  );
};

export const QUERY = graphql`
  query HomeMeta {
    sanityHomePage {
      _rawMetadata(resolveReferences: { maxDepth: 10 })
      data {
        _rawTitleBreadcrumb
      }
    }
  }
`;

export default IndexPage;
