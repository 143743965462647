import React, { useState } from "react";
import Swiper from "swiper";
import { Carousel } from "../carousel/Carousel";
import { graphql, useStaticQuery } from "gatsby";
import { CarouselHomeQuery } from "../../../graphql-types";
import { SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./homeCarousel.module.scss";
import { usePageContext } from "../context/PageContext";
import { StyledLink } from "../styled/StyledLink";
import { CarouselPagination } from "../carousel/CarouselPagination";
import ReactPlayer from "react-player/youtube";

export const HomeCarousel = () => {
  const { carouselHome } = useStaticQuery<CarouselHomeQuery>(QUERY);
  const { lang } = usePageContext();
  const [swiper, setSwiper] = useState<Swiper>();

  return (
    <>
      <div className={styles.carouselContainer}>
        <Carousel setSwiper={setSwiper}>
          {carouselHome?.data?.carouselItems?.map(item => (
            <SwiperSlide key={item?._key}>
              <StyledLink
                target={item?.button?.target ?? ""}
                path={item?.button?.url ?? ""}
                disabled={item?.button?.url === undefined}
              >
                <div className={styles.slide}>
                  {item?.bgType === "image" ? (
                    <GatsbyImage
                      image={item?.backgroundImg?.image?.asset?.gatsbyImageData}
                      alt={"tours"}
                      className={styles.image}
                    />
                  ) : (
                    <div className={styles.image}>
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        url={item?.backgroundVideo ?? ""}
                        muted
                        playing={true}
                        loop
                        playsinline
                        // className={styles.reactPlayer}
                        config={{
                          playerVars: {
                            showinfo: 0,
                            controls: 0,
                            autoplay: 1,
                            loop: 0,
                            modestbranding: 1,
                            disablekb: 1,
                          },
                        }}
                        type="video/mp4"
                      />
                    </div>
                  )}
                  <div className={styles.contentWrapper}>
                    <div>
                      <div className={styles.title}>
                        {item?._rawDescription?.[lang]}
                      </div>
                      {item?.button?._rawLabel?.[lang] && (
                        <div className={styles.button}>
                          {item?.button?._rawLabel?.[lang]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </StyledLink>
            </SwiperSlide>
          ))}
        </Carousel>
      </div>
      <CarouselPagination swiper={swiper} spacing={10} />
    </>
  );
};

const QUERY = graphql`
  query CarouselHome {
    carouselHome: sanityHomePage {
      data {
        carouselItems {
          _key
          _rawDescription
          bgType
          backgroundVideo
          backgroundImg {
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          button {
            _rawLabel
            url
            target
          }
        }
      }
    }
  }
`;
