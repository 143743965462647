import React, { useState } from "react";
import Swiper from "swiper/types/swiper-class";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./carouselControls.module.scss";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-light-svg-icons";

interface Props {
  swiper: Swiper | undefined;
  className?: string;
}

export const CarouselControls: React.FC<Props> = ({
  children,
  swiper,
  className,
}) => {
  const [_, setActiveIndex] = useState(0);
  swiper?.on("slideChange", () => setActiveIndex(swiper.activeIndex));

  return (
    <div className={styles.wrapper}>
      <div
        onClick={() => swiper?.slidePrev()}
        className={`${className} ${swiper?.isBeginning ? styles.inactive : ""}`}
      >
        <FontAwesomeIcon icon={faArrowLeft} className={styles.icon} />
      </div>
      {children}
      <div
        onClick={() => swiper?.slideNext()}
        className={`${className} ${swiper?.isEnd ? styles.inactive : ""}`}
      >
        <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />
      </div>
    </div>
  );
};
