import React, { useState } from "react";
import Swiper from "swiper";
import { Carousel } from "../carousel/Carousel";
import { graphql, useStaticQuery } from "gatsby";
import { SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { usePageContext } from "../context/PageContext";
import { TestimonialsQuery } from "../../../graphql-types";
import BlockContent from "@sanity/block-content-to-react";
import * as styles from "./testimonials.module.scss";
import { CarouselControls } from "../carousel/CarouselControls";

export const Testimonials = () => {
  const { sanityHomePage } = useStaticQuery<TestimonialsQuery>(QUERY);
  const { lang } = usePageContext();
  const [swiper, setSwiper] = useState<Swiper>();

  return (
    <div className={styles.wrapper}>
      <h2>{sanityHomePage?.data?._rawTestimonialTitle?.[lang]}</h2>
      <CarouselControls className={styles.controls} swiper={swiper}>
        <Carousel setSwiper={setSwiper}>
          {sanityHomePage?.data?.testimonials?.map(item => (
            <SwiperSlide key={item?._key}>
              <div className={styles.slide}>
                <BlockContent blocks={item?._rawContent?.[lang]} />
                <p className={styles.author}>{item?._rawAuthor?.[lang]}</p>
                <GatsbyImage
                  image={item?.logo?.image?.asset?.gatsbyImageData}
                  alt={item?.logo?._rawAlt?.[lang] ?? "tours"}
                  className={styles.image}
                  objectFit={"contain"}
                />
              </div>
            </SwiperSlide>
          ))}
        </Carousel>
      </CarouselControls>
    </div>
  );
};

const QUERY = graphql`
  query Testimonials {
    sanityHomePage {
      data {
        _rawTestimonialTitle
        testimonials {
          _key
          _rawAuthor
          _rawContent
          logo {
            _rawAlt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
